import Trix from "trix"

const config = Trix.config

function fa_icon(name) { return `<i class='fa fa-${name}'></i>` }

const { lang } = config

config.textAttributes.underline = {
  tagName: "u",
  parser: (element) => {
    const style = window.getComputedStyle(element)

    return style["textDecoration"] == "underline"
  }
}


config.toolbar = {
  getDefaultHTML: () => {
    const template = `
      <div class="flex flex-wrap py-1 mb-1 -my-1 bg-white">
        <div class='mr-2 my-1'>
          <div class="btn-group" data-trix-button-group="text-tools">
            <button type="button" class="trix-button" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1">${fa_icon("bold")}</button>
            <button type="button" class="trix-button" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1">${fa_icon("italic")}</button>
            <button type="button" class="trix-button" data-trix-attribute="underline" data-trix-key="u" title="${lang.underline}" tabindex="-1">${fa_icon("underline")}</button>
            <button type="button" class="trix-button" data-trix-attribute="strike" title="${lang.strike}" tabindex="-1">${fa_icon("strikethrough")}</button>
            <button type="button" class="trix-button" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${lang.link}" tabindex="-1">${fa_icon("link")}</button>
          </div>
        </div>
        <div class='mr-2 my-1'>
          <div class="btn-group">
            <button type="button" class="trix-button" data-trix-attribute="quote" title="${lang.quote}" tabindex="-1">${fa_icon("quote-right")}</button>
            <button type="button" class="trix-button" data-trix-attribute="code" title="${lang.code}" tabindex="-1">${fa_icon("code")}</button>
            <button type="button" class="trix-button" data-trix-attribute="bullet" title="${lang.bullets}" tabindex="-1">${fa_icon("list-ul")}</button>
            <button type="button" class="trix-button" data-trix-attribute="number" title="${lang.numbers}" tabindex="-1">${fa_icon("list-ol")}</button>
            <button type="button" class="trix-button" data-trix-action="decreaseNestingLevel" title="${lang.outdent}" tabindex="-1">${fa_icon("outdent")}</button>
            <button type="button" class="trix-button" data-trix-action="increaseNestingLevel" title="${lang.indent}" tabindex="-1">${fa_icon("indent")}</button>
          </div>
        </div>
        <div class='mr-2 my-1'>
          <button type="button" class="trix-button" data-trix-action="attachFiles" title="Attach Files" tabindex="-1">${fa_icon("camera")}</button>
        </div>
        <div class='ml-auto my-1'>
          <div class="btn-group" data-trix-button-group="history-tools">
            <button type="button" class="trix-button" data-trix-action="undo" data-trix-key="z" title="${lang.undo}" tabindex="-1">${fa_icon("undo")}</button>
            <button type="button" class="trix-button" data-trix-action="redo" data-trix-key="shift+z" title="${lang.redo}" tabindex="-1">${fa_icon("redo")}</button>
          </div>
        </div>
      </div>
      <div class="absolute" data-trix-dialogs>
        <div class="p-3 bg-white shadow border rounded" data-trix-dialog="href" data-trix-dialog-attribute="href">
          <input type="url" name="href" class="form-control" placeholder="${lang.urlPlaceholder}" aria-label="${lang.url}" required data-trix-input>

          <div class='mt-2'>
            <input type="button" class="trix-button" value="${lang.link}" data-trix-method="setAttribute">
            <input type="button" class="trix-button" value="${lang.unlink}" data-trix-method="removeAttribute">
          </div>
        </div>
      </div>`

    return template
  }
}