import { Controller } from "@hotwired/stimulus"
import { addClasses, removeClasses } from "../utils/classes"

// Connects to data-controller="toggle"
export default class extends Controller {
  static classes = ["active", "inactive"]
  static targets = ["toggleable"]

  static values = {
    active: Boolean
  }

  get toggleTarget() { return this.hasToggleableTarget ? this.toggleableTarget : this.element }

  connect() {
  }

  activate() {
    this.activeValue = true
  }

  deactivate() {
    this.activeValue = false
  }

  toggle() {
    this.activeValue = !this.activeValue
  }

  activeValueChanged() {
    if (this.activeValue) {
      addClasses(this.toggleTarget, this.activeClasses)
      removeClasses(this.toggleTarget, this.inactiveClasses)
    } else {
      removeClasses(this.toggleTarget, this.activeClasses)
      addClasses(this.toggleTarget, this.inactiveClasses)
    }
  }
}
