import { Controller } from "@hotwired/stimulus"

import { dispatchEvent } from "../utils/events"

// Connects to data-controller="click-outside"
export default class extends Controller {
  static values = {
    eventName: { type: String, default: "click:outside" },
  }
  connect() {
    this.handleClick = this.handleClick.bind(this)
    this.observe()
  }

  disconnect() {
    this.unobserve()
  }

  observe() {
    window.addEventListener("click", this.handleClick)
  }

  unobserve() {
    window.removeEventListener("click", this.handleClick)
  }

  handleClick(e) {
    if (this.element.contains(e.target)) { return }

    this.triggerEvent()
  }

  triggerEvent() {
    dispatchEvent(this.element, this.eventNameValue)
  }
}
