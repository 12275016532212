import { Controller } from "@hotwired/stimulus"

import Chartkick from "chartkick"
import "chartkick/chart.js"


// Connects to data-controller="chart"
export default class extends Controller {
  static targets = ["chart", "datum", "series"]
  static values = {
    type: { type: String, default: "LineChart" },
    options: { type: Object, default: {}}
  }

  connect() {
    this.createChart()
  }

  typeValueChanged() {
    if (this.chart) {
      this.destroyChart()
      this.createChart()
    }
  }

  disconnect() {
    this.destroyChart()
  }

  createChart() {
    this.chart = new Chartkick[this.typeValue](this.chartTarget, this.chartData, this.optionsValue)
  }

  destroyChart() {
    if (this.chart) {
      this.chart.destroy()
      this.chart = null
    }
  }

  datumTargetConnected() {
    this.updateData()
  }

  datumTargetDisconnected() {
    this.updateData()
  }


  updateData() {
    if (this.chart) {
      this.chart.updateData(this.chartData)
    }
  }

  get chartData() {
    let data = []

    this.datumTargets.forEach((dp) => {
      data.push([dp.dataset.key, dp.dataset.value])
    })

    return data
  }
}
