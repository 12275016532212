import { Controller } from "@hotwired/stimulus"

import { dispatchEvent } from "../utils/events"

// Connects to data-controller="infobox"
export default class extends Controller {
  static targets = ["infobox"]
  static classes = ["open", "close"]
  static values  = {
    open: { type: Boolean, default: false },
    enableTransition: { type: Boolean, default: false }
  }

  get transition() { return this.application.getControllerForElementAndIdentifier(this.element, "transition") }

  connect() {
  }

  toggle() {
    this.openValue = !this.openValue
  }

  open() {
    this.openValue = true
  }

  close() {
    this.openValue = false
  }

  openValueChanged() {
    if (!this.transition) { return }

    if (this.openValue) {
      this.transition.enter()
    } else {
      this.transition.leave()
    }
  }


  emitEvent() {
    const eventType = this.openValue ? "open" : "close"

    this.dispatch(`infobox:${eventType}`)
  }

  dispatch(eventName, options) {
    dispatchEvent(this.element, eventName, options)
  }
}
