import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="transition-button"
export default class extends Controller {
  static values = {
    targetId: String,
  }

  get transitionTargetElement() { return document.getElementById(this.targetIdValue) }
  get transitionController() { return this.application.getControllerForElementAndIdentifier(this.transitionTargetElement, "transition") }

  connect() {
  }

  toggle() {
    this.transitionController.toggle()
  }

  leave() {
    this.transitionController.leave()
  }

  enter() {
    this.transitionController.enter()
  }
}
