import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-frame--proxy"
export default class extends Controller {
  static targets = ["frame"]

  get frameElement() { return this.element.closest("turbo-frame") }

  clear(e) {
    if (e) e.preventDefault();

    this.frameElement.innerHTML = ""
  }
}
