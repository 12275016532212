import { Controller } from "@hotwired/stimulus"
import TomSelect from 'tom-select'
import { get } from "@rails/request.js"



// Connects to data-controller="tom-select"
export default class extends Controller {
  static values = {
    loadUrl: String,
    create: { type: Boolean, default: false },
    createOnBlur: { type: Boolean, default: true },
    persist: { type: Boolean, default: true },
    autogrow: Boolean,
    caretPosition: { type: Boolean, default: true },
    clear: { type: Boolean, default: true },
  }

  connect() {
    const options = this.buildTomSelectOptions()

    this.element.value = this.element.value.replace(/,\s+/g, ",")

    this.tomSelect = new TomSelect(this.element, options)
  }

  disconnect() {
    if (this.tomSelect) {
      this.tomSelect.destroy()
      this.tomSelect = null
    }
  }

  buildTomSelectOptions() {
    let options = {
      create: this.createValue,
      persist: this.persistValue,
      createOnBlur: this.createOnBlurValue,
      plugins: []
    }

    if (this.loadUrlValue) {
      options.load = (query, callback) => this.search(query, callback)
    }

    if (this.caretPositionValue) {
      options.plugins.push("caret_position")
    }

    if (this.autogrowValue) {
      options.plugins.push("autogrow_input")
    }

    if (this.clearValue) {
      options.plugins.push("clear_button")
    }

    return options
  }

  async search(query, callback) {
    const url = this.loadUrlValue
    const response = await get(url, { responseKind: "json", query: { q: query } })

    if (response.ok) {
      const json = await response.json

      const values = json.map((item) => {
        return { text: item.name, value: item.name }
      })

      callback(values)
    } else {
      callback()
    }
  }
}
