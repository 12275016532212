import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-frame-spinner"
export default class extends Controller {
  static targets = [ "spinner", "frame" ]
  static values = {
    spinnerDelay: { type: Number, default: 200 }
  }

  loading() {
    this.cancelSpinnerDelay()

    this.spinnerDelay = setTimeout(this.showSpinner.bind(this), this.spinnerDelayValue)
  }

  rendered() {
    this.cancelSpinnerDelay()
  }


  showSpinner() {
    this.frameTarget.innerText = ""
    this.frameTarget.insertAdjacentHTML("beforeend", this.spinnerTarget.innerHTML)

    this.cancelSpinnerDelay()
  }

  cancelSpinnerDelay() {
    if (!this.spinnerDelay) { return; }

    clearTimeout(this.spinnerDelay)
    this.spinnerDelay = null
  }


}
