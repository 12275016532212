import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="default-action"
export default class extends Controller {
  cancel(e) {
    this.preventDefault(e)
    this.stopPropagation(e)
  }

  preventDefault(e) {
    if (e) {
      e.preventDefault()
    }
  }

  stopPropagation(e) {
    if (e) {
      e.stopPropagation()
    }
  }
}
